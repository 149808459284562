
	@charset "utf-8";

	@import "@/styles/mixins";
	@import "@/styles/variables";

	.title,
	.subtitle {
		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}

	.table {
		.has-text-right {
			white-space: nowrap;
		}

		.svg-icon {
			@include square(1em);
		}

		.is-public-holiday {
			border-left: .25rem solid $public-holiday-color;
		}

		.is-school-holiday {
			border-left: .25rem solid $school-holiday-color;
		}

		.is-school-holiday-regional {
			border-left: .25rem solid $school-holiday-regional-color;
		}

		.is-other {
			border-left: .25rem solid $grey-lighter; // $grey-lightest;
		}
	}
