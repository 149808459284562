
	@charset "utf-8";

	@import "@/styles/mixins";
	@import "@/styles/variables";

	$margin: 2.5rem;

	.floating-buttons {
		// $margin: 2.5rem;

		position: fixed;
		// bottom: $margin;
		// right: $margin;

		ul > li {
			&:not(:last-child) {
				margin-bottom: $margin / 4;
			}
			/* kvoli tomu, ze print je hidden na mobile, ale element v dome ostava
			&:not(:first-child) {
				margin-top: $margin / 4;
			}
			*/
		}

		.button {
			@include square($margin);
			z-index: 95;

			.svg-icon.is-brand {
				@include square(1.25rem);
				padding: .125rem;
			}
		}
	}

	@media screen and (max-width: $desktop - 1px) {
		.floating-buttons {
			bottom: $margin / 2;
			right: $margin / 2;
		}
	}

	@media screen and (min-width: $desktop) {
		.floating-buttons {
			bottom: $margin;
			right: $margin;
		}
	}
