@charset "utf-8";

@import "variables";
@import "mixins";

a, .button, .input, .burger {
	transition-property: color, background-color, border-color, box-shadow, opacity;
	transition-duration: $transition-duration;
	transition-timing-function: $transition-timing-function;
}
/*
a, .button, .burger, svg {
	outline: none;
}
*/
a, .button, .burger {
	cursor: pointer;
}

a {
	&:hover,
	&:active,
	&:focus,
	&:focus-within {
		//TODO: namiesto underline spravit border bottom a animovat ho
		text-decoration: underline;
	}
}

.button {
	&.is-primary {
		color: $white-ter;
	}

	&.is-rounded.has-icon-only {
		padding: calc(.5em - 1px);
	}
}

.section:not(.cookie-consent-section) {
/*
	&:first-of-type {
		padding-top: 5rem;
	}
*/
	&:last-of-type {
		padding-bottom: 5rem;
	}
}

.is-borderless {
	border: none !important;
}

img {
	aspect-ratio: attr(width) / attr(height);
}

.image {
	@include flex-col-center;

	img {
		width: auto;
	}
}

.svg-icon {
	@include square(1.5rem);
/*
	&.is-small {
		height: 1.25rem;
	}
*/
}

[disabled] {
	// opacity: .5;
	cursor: no-drop;
}

/*
@media screen and (min-width: $desktop) {
	.button.is-rounded.has-icon-only-desktop {
		padding: calc(.5em - 1px);
	}
}
*/
/*
// boxed bulma-switch
input[type=checkbox].switch + label {
	&::before {
		box-shadow: 0 0 .75rem 0 rgba(0, 0, 0, .25); // namiesto .box -> vyraznejsie
	}
}
*/
