
	@charset "utf-8";

	@import "@/styles/mixins";
	@import "@/styles/variables";

	$margin: 3rem;

	.columns > .column:last-child {
		margin-left: $margin;

		.banner {
			&.is-hidden-desktop {
				margin: $margin * -.375 auto $margin;
			}

			&.is-hidden-touch {
				position: sticky;
				top: $margin;
				margin-top: $margin / 2;
			}

			p {
/*
				&:not(:last-child) {
					margin-bottom: 1rem;
				}
*/

				&.has-background-primary {
					$padding: .5rem;
					padding: $padding;
					// border-bottom-left-radius: $padding;
					// border-bottom-right-radius: $padding;

					a {
						color: $white-ter; // = light
						font-weight: bold;
					}
				}
			}
		}
	}

	@media screen and (max-width: $desktop - 1px) {
		.columns {
			display: flex;
			flex-direction: column-reverse;

			& > .column:last-child {
				margin: 0;
			}
		}
	}
