
	@charset "utf-8";

	@import "@/styles/mixins";
	@import "@/styles/variables";

	.day {
		transition-property: color;
		transition-duration: $transition-duration;
		transition-timing-function: $transition-timing-function;
	}

	@media /* print, */ screen and (max-width: $desktop - 1px) {
		.calendar {
			@include flex-col;

			.col-touch {
				@include flex-col;
/*
				&:not(:last-of-type) .row-touch,
				&:last-of-type .row-touch:not(:last-of-type) {
					border-bottom: 1px solid $grey-light;
				}
*/
				.row-touch {
					@include flex-row;
					padding: .5rem 1rem;

					&:not(.is-last-day) {
						border-bottom: 1px solid $grey-light;
					}

					&.is-weekend {
						background-color: $grey-lightest;
					}

					&.is-school-holiday .day {
						color: $school-holiday-color;
					}

					&.is-school-holiday-regional .day {
						color: $school-holiday-regional-color;
					}

					&.is-public-holiday .day {
						color: $public-holiday-color;
					}

					&.is-today .tag {
						margin-left: .5rem;
					}	

					.weekday,
					.tag,
					.day,
					.nameday {
						@include flex-col;
					}

					.weekday,
					.day {
						min-width: 2rem;
					}

					.day {
						padding-right: .5rem; // for tooltip
					}

					.nameday {
						flex: 1;
					}
				}
			}
		}
	}

	@media print, screen and (min-width: $desktop) {
		$cell-size: 8.75rem;

		.columns {
			margin-bottom: 1rem;

			& > .column {
				&:not(.is-narrow) {
					@include flex-col-center;
				}
			}
		}

		.calendar {
			@include flex-col;
			max-width: $cell-size * 7;

			&:not(.hide-school-holidays) .row-desktop .col-desktop {
				&.is-school-holiday .day {
					color: $school-holiday-color;
				}

				&.is-school-holiday-regional .day {
					color: $school-holiday-regional-color;
				}
			}

			&:not(.hide-public-holidays) .row-desktop .col-desktop {
				&.is-public-holiday .day {
					color: $public-holiday-color;
				}
			}

			.row-desktop {
				@include flex-row;

				&:first-of-type .col-desktop {
					height: auto;
					padding: .25rem;
				}

				&:not(:last-of-type) {
					border-bottom: 1px solid $grey-light;
				}

				.col-desktop {
					@include flex-col;
					@include square($cell-size);
					padding: 1.125rem;

					&.is-disabled {
						opacity: .375;
					}
/*
					&:hover {
						box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .25);
					}
*/
					&.is-weekend {
						background-color: $grey-lightest;
/*
						&:not(:last-of-type) {
							border-right: 1px solid $white-ter;
						}
*/
					}

					&:not(.is-today) .day {
						padding-top: 1.5rem;
					}

					&.is-today .tag {
						max-width: 3rem;
						margin: 0 auto;
					}

					.week,
					.tag,
					.day,
					.nameday {
						@include flex-col;
					}

					.week {
						position: absolute; top: 0; left: 0;
						padding: .25rem;
						font-size: .875rem;
					}

					.day {
						font-size: 2.25rem;
					}

					.nameday {
						font-size: .75rem;
					}
				}
			}
		}
	}
