
	@charset "utf-8";

	//TODO: presunut do styles/base.scss

	@import "@/styles/mixins";
	@import "@/styles/variables";

	html, html > body, #app {
		@include rectangle(100%, 100%);
		min-height: 100%;
	}

	html > body, #app {
		@include flex-col;
	}

	#app .main {
		flex: 1;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition-property: opacity;
		transition-duration: $transition-duration;
		transition-timing-function: $transition-timing-function;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	[v-cloak] {
		display: none !important;
	}
