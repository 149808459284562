
	@charset "utf-8";

	@import "@/styles/mixins";
	@import "@/styles/variables";

	.cookie-consent-section {
		position: fixed; bottom: 0;
		width: 100%;
		padding: 1.5rem .75rem; // half of .section
		z-index: 100;

		a {
			color: $white-ter;
			font-weight: bold;
		}
	}
