@charset "utf-8";

@import "variables";

@mixin flex {
	display: flex;
}

@mixin flex-row {
	@include flex;
	flex-direction: row;
}

@mixin flex-col {
	@include flex;
	flex-direction: column;
}

@mixin flex-col-center {
	@include flex-col;
	align-items: center;
	justify-content: center;
}

@mixin square($size: 0) {
	width: $size; height: $size;
}

@mixin rectangle($width: 0, $height: 0) {
	width: $width; height: $height;
}
