@charset "utf-8";

// @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;900&display=swap");

@import "fonts";
@import "variables";
@import "mixins";

$link: $primary;

// @import "~bulma/sass/utilities/_all";

$family-sans-serif: "Lato", Avenir, Helvetica, Arial, sans-serif;
$weight-bold: 900;
$body-color: $grey-dark;
$widescreen-enabled: false;
$fullhd-enabled: false;

$navbar-burger-color: $white-ter;
$navbar-item-hover-background-color: transparent !important; // rgba(10, 10, 10, .1) !important;
$navbar-item-active-background-color: transparent !important;
$navbar-dropdown-background-color: $primary; // $black-ter;
$navbar-dropdown-item-hover-color: $white-ter;

$dropdown-divider-background-color: rgba(255, 255, 255, 0.625);
$navbar-dropdown-border-top: 1px solid $dropdown-divider-background-color;

$hero-body-padding: 3rem 1.5rem 3.5rem;

$box-background-color: rgba(255, 255, 255, 0.1);
$box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.25);

$tabs-link-padding: 0.375em 0.875em;
$tabs-boxed-link-radius: 0.375em;

$section-padding: 3rem 1rem; // 3rem 1.5rem;

$message-body-padding: 2.5rem 3rem;

$footer-background-color: $primary;
$footer-color: $white-ter;
$footer-padding: $section-padding;

$tooltip-animation-duration: $transition-duration;
$tooltip-animation-transition-timing-function: $transition-timing-function;

$tooltip-background-color: $grey-dark;
$tooltip-background-opacity: 0.95;

@import "~bulma/bulma";
@import "~bulma-switch/src/sass";
@import "~@creativebulma/bulma-tooltip/src/sass";

// bulma-switch override
.switch[type="checkbox"] {
	display: none;

	& + label {
		padding-top: 0.15rem;
	}
}
