
	@charset "utf-8";

	@import "@/styles/mixins";
	@import "@/styles/variables";

	.footer {
		p,
		ul {
			&:not(:last-child) {
				margin-bottom: 1rem;
			}
		}

		a, .button.is-ghost {
			color: $white-ter;
			font-weight: bold;
		}

		.button.is-ghost {
			height: inherit;
			border: none;
		}

		.svg-icon {
			@include square(1em);
			animation: heartbeat 1s infinite;
		}
/*
		.pp-badge {
			margin: 6px 0;
		}

		.ws-badge {
			width: auto; height: 2.5rem;
		}
*/
		.license {
			// display: flex;
			// flex-direction: row;
			// align-items: center;
			// justify-content: center;
		}
	}

	@media screen and (min-width: $desktop) {
		.footer {
			ul {
/*
				&:first-of-type {
					& > li:not(:last-of-type) {
						margin-right: .5rem;
					}
				}
*/
//				&:last-of-type {
					& > li:not(:last-of-type) {
						&::after {
							content: "|";
							padding: 0 .25rem;
						}
					}
//				}
			}
		}
	}

	@keyframes heartbeat {
		0% { transform: scale(.75); }
		15% { transform: scale(.95); }
		30% { transform: scale(.75); }
		45% { transform: scale(.95); }
		60% { transform: scale(.75); }
		100% { transform: scale(.75); }
	}
