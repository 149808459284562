@charset "utf-8";

// @import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
// @import "~bulma/sass/utilities/derived-variables";

$school-holiday-color: mediumseagreen;
$school-holiday-regional-color: steelblue;
$public-holiday-color: indianred;
// $dst-color: gold;

$primary: teal;

// $paypal: #009cde;
// $pay-by-square: #6fa4d7;

$desktop: 980px + 2 * $gap;

$transition-duration: 0.25s;
$transition-timing-function: ease;
