
	@charset "utf-8";

	@import "@/styles/mixins";
	@import "@/styles/variables";

	// bulma override
	.hero.is-primary {
		.hero-head {
			.navbar-item,
			.navbar-link {
				&:hover,
				&:active,
				&:focus,
				&:focus-within,
				&.is-active {
					text-decoration: none;
				}
			}
		}

		.hero-body .box {
			transition-property: transform;
			transition-duration: $transition-duration;
			transition-timing-function: $transition-timing-function;

			&:hover {
				transform: scale(1.1);
			}
		}

		.hero-foot .tabs {
			// ul > li {
				a {
					&:hover,
					&:active,
					&:focus,
					&:focus-within {
						text-decoration: none;
					}
				}

				// firefox fix
				.is-active {
					color: $primary !important;
				}
			// }
		}
	}

	@media screen and (max-width: $desktop - 1px) {
		.hero {
			/*
			.hero-head {
				.button .svg-icon {
					margin-right: .5rem;
				}
			}
			*/
			.hero-foot .tabs + .tabs-help {
				position: absolute;
				bottom: -1.5rem;
				left: 0;
				width: 100%;
				font-size: .875rem;
			}
		}
	}

	/* dropodown doplnene styly */

	@media screen and (min-width: $desktop) {
		.hero {
			.hero-head {
				// override, lebo tam nieco davalo padding-right: 3rem;
				.navbar-dropdown .navbar-item {
					padding: .375rem 1rem;
				}
				/*
				.navbar-end .navbar-item {
					&:not(:first-of-type) {
						padding-left: 0;
					}
					&:not(:last-of-type) {
						padding-right: 0;
					}
				}
				*/
			}

			.hero-body .columns > .column:first-of-type {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
	}

	//TODO: rozhodit do MQ vyssie
	// drowpdown arrow svg icon
	.hero .hero-head .has-dropdown {
		.is-arrowless {
			padding-right: 1.5rem;

			.svg-icon {
				position: absolute;
				top: .5rem;
				right: .5rem;

				@media screen and (min-width: $desktop) {
					top: .75rem;
					right: .75rem;
				}
			}

			@media screen and (min-width: $desktop) {
				padding-right: 2.25rem;
			}
		}
	}
