
	@charset "utf-8";

	@import "@/styles/mixins";
	@import "@/styles/variables";

	$margin: 2.5rem;

	.message {
		position: fixed;
		bottom: $margin;
		left: $margin;
		margin-bottom: 0; // kvoli bulme...
		box-shadow: 0 0 .75rem 0 rgba(0, 0, 0, .25);
		z-index: 125;

		.message-body {
			padding: 1.25rem 1.5rem;
			border-color: $primary;

			p {
				margin-bottom: 1rem;
			}
		}
	}

	@media screen and (max-width: $desktop - 1px) {
		.message {
			right: $margin;
		}
	}
