@charset "utf-8";

@import "variables";
@import "mixins";

@media screen {
	.is-hidden-screen {
		display: none !important;
	}
}

@media print {
	.is-hidden-print {
		display: none !important;
	}

	.has-text-centered-print {
		text-align: center !important;
	}

	.calendar-section,
	.holidays-section {
		.title:not(:last-child) {
			margin-bottom: 1.25rem * 1.5 !important;
		}
	}

	.calendar-section {
		.form,
		.weekday,
		.is-today .tag {
			display: none !important;
		}
		
		.is-today .day {
			padding-top: 1.5rem;
		}
	}

	.holidays-section {
		.table + .title {
			padding-top: 3rem;
		}
	}

	.section {
		padding: 3rem; margin: 0;
		break-after: page;
		// border: 1px dashed pink;
	}
/*
	.holidays-section .columns > .column {
		break-before: page;
	}
*/
/*
	.holidays-section .table:nth-child(odd):not(:last-child) {
		break-after: page;
	}
*/
	.holidays-section .table.break-after {
		break-after: page;
	}
}
